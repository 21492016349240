<script>
import {mapActions} from "pinia";
import {authStore} from "@/store/authStore";

export default {
  name: "CookiePolicyMessage",
  methods: {
    ...mapActions(authStore, [
      'setCookieAcceptedAction',
      'checkCookieAcceptedAction',
    ]),
    acceptCookie() {
      this.setCookieAcceptedAction();
      this.checkCookieAcceptedAction();
    },
  }
}
</script>

<template>
  <div class="cookie-policy-message">
    <div class="cookie-policy-message__content">
      <div class="cookie-policy-message__info">
        <span class="title">
          {{$t('header.message.cookiePolicyMessage.title')}}
        </span>
        <p class="cookie-policy-message__text">
          <span>{{$t('header.message.cookiePolicyMessage.text1')}}</span>
          <span>
            {{$t('header.message.cookiePolicyMessage.text2')}}
            <a href="/html/documents/Cookies_policy_20180717_2357_PeoplePolitics.pdf" target="_blank" class="link">
              {{$t('header.message.cookiePolicyMessage.link')}}
            </a>.
          </span>
        </p>
      </div>
      <div class="cookie-policy-message__controls">
        <span class="control" @click="acceptCookie">{{$t('header.message.cookiePolicyMessage.ok')}}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cookie-policy-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  z-index: 1001;
  background: #18bafe;
  &__content {
    display: flex;
    justify-content: space-between;
    width: 970px;
  }

  &__info {
    padding-top: 10px;
    font-weight: 600;
    color: #fff;
    line-height: 18px;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__controls {
    display: flex;
    align-items: center;
    padding-right: 15px;

    .control {
      color: #fff;
      cursor: pointer;
    }
  }

  .title {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .link {
    color: #fff;
    text-decoration: underline;
  }

}
</style>