<script>
import i18n from "@/i18n";
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";

export default {
  name: "NotApprovedUserMessage",
  computed: {
    ...mapState(authStore, [
      'userInfo',
      'userActivated',
    ]),
    userName() {
      return `${this.userInfo.firstName} ${this.userInfo.lastName}`;
    },
    userEmail() {
      return `${this.userInfo.email}`;
    },
    noApprovedUserMsg() {
      return `${i18n.global.t('header.message.noApprovedUser.message.p1')} ${this.userName}! ${i18n.global.t('header.message.noApprovedUser.message.p2')} ${this.userEmail} ${i18n.global.t('header.message.noApprovedUser.message.p3')}`;
    }
  },
  methods: {
    ...mapActions(authStore, [
      'resendRegistrationEmail',
      'toggleChangeEmailModalState'
    ]),
  }
}
</script>

<template>
  <div class="not-approved-message">
    <div class="not-approved-message__content">
      <span class="not-approved-message__info">
        {{noApprovedUserMsg}}
      </span>
      <ul class="not-approved-message__items">
        <li class="not-approved-message__item">
          <span class="not-approved-message__link"
                @click="resendRegistrationEmail"
          >
            {{$t('header.message.noApprovedUser.sendMessageAgain')}}
          </span>
        </li>
        <li class="not-approved-message__item">
          <span class="not-approved-message__link"
                @click="toggleChangeEmailModalState"
          >
            {{$t('header.message.noApprovedUser.changeEmail')}}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.not-approved-message {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  position: fixed;
  height: 45px;
  background: #fe8f18;
  &__content {
    display: flex;
    justify-content: space-between;
    width: 970px;
  }
  &__info {
    font-weight: 700;
    color: #ffffff;
  }

  &__items {
    display: flex;
  }
  &__item {
    position: relative;
    padding-left: 13px;
    margin-left: 8px;

    &:first-child {
      margin: 0;
      padding: 0;
      &:before {
        content: none;
      }
    }

    &:before {
      content: '\2022';
      font-size: 12px;
      color: #ffffff;
      position: absolute;
      left: 0;
      top: 1px;
    }
  }
  &__link {
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
  }
}
</style>