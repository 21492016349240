<script>
import {mapActions, mapState} from "pinia";
import {bankIdStore} from "@/store/bankIdStore";
import Dialog from "primevue/dialog";
import BankIdForm from "@/components/BankId/BankIdForm.vue";

export default {
  name: "BankIdModal",
  components: {
    Dialog,
    BankIdForm
  },
  computed: {
    ...mapState(bankIdStore, ['bankIdModalState']),
    modalState: {
      get() {
        return this.bankIdModalState;
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions(bankIdStore, [
       "showBankIdModal",
        "hideBankIdModal"
    ]),
    closeModal() {
      this.hideBankIdModal();
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          :header="'Mobile BankID'"
          class="bank-id-modal"
          modal
  >
    <div>
      <BankIdForm @on-cancel="closeModal"/>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.bank-id-modal {
  min-width: 660px;
  .p-dialog-content {
    padding: 23px 32px !important;
  }
}

</style>