<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import Dialog from "primevue/dialog";
export default {
  name: "LeavePageConfirmModal",
  components: {
    Dialog
  },
  computed: {
    ...mapState(authStore, [
      'leavePageModal',
      'unsavedChangesCallback'
    ]),
    modalState: {
      get() {
        return this.leavePageModal;
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions(authStore, [
      'toggleLeavePageModal',
    ]),
    closeModal() {
      this.toggleLeavePageModal();
    },
    leavePage() {
      if (this.unsavedChangesCallback) {
        this.unsavedChangesCallback();
      }
      this.toggleLeavePageModal();
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="leave-page-modal"
          modal
          :header="$t('leavePage.title')"
  >
    <div>
      <p class="modal-text">
        {{$t('leavePage.text')}}
      </p>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="leavePage">
        {{$t('leavePage.leavePage')}}
      </button>
      <button class="p-dialog-primary-btn" @click="closeModal">
        {{$t('leavePage.Stay')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.leave-page-modal {
  max-width: 425px;
  .p-dialog-content {
    padding: 23px 30px;
  }
  .modal-text {
    line-height: 18px;
  }
}
</style>