<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {useVuelidate} from "@vuelidate/core";
import i18n from "@/i18n";
import moment from 'moment-timezone';
import Dialog from "primevue/dialog";
import Select from 'primevue/select';
import DatePicker from 'primevue/datepicker';
import {email, helpers, required, minLength, sameAs} from "@vuelidate/validators";

export default {
  name: "SignUpModal",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Dialog,
    Select,
    DatePicker
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      repeatEmail: '',
      password: '',
      gender: { "name": "Gender", "code": "NONE" },
      birthday: null,
      submitBtnState: true,
      firstNameActive: false,
      lastNameActive: false,
      emailActive: false,
      repeatEmailActive: false,
      passwordActive: false,
    }
  },
  validations () {
    return {
      firstName: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      lastName: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      email: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required),
        email: helpers.withMessage(i18n.global.t('validations.email'), email)
      },
      repeatEmail: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required),
        email: helpers.withMessage(i18n.global.t('validations.email'), email),
        sameAsRawValue: helpers.withMessage(i18n.global.t('validations.emailDifferent'), sameAs(this.email))
      },
      password: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required),
        minLength: minLength(6),
        notSimplestThan: helpers.withMessage(i18n.global.t('validations.simplePassword'),  val => /^(?=.*[0-9])(?=.*[a-zA-Z])(.+)$/.test(val)),
      },
      birthday: {
        required: helpers.withMessage(i18n.global.t('validations.enterBirthday'), required)
      },

    }
  },
  computed: {
    ...mapState(authStore, [
      'signUpModalState',
    ]),
    modalState: {
      get() {
        return this.signUpModalState;
      },
      set() {
        this.closeModal();
      },
    },
    userAgeCheck() {
      const birthday = moment(this.birthday, 'YYYY-MM-DD', true);
      const age = moment().diff(birthday, 'years');

      return this.birthday ? (age >= 13) : true;
    },
    hasChanges() {
      return this.firstName.length > 0
          || this.lastName.length > 0
          || this.email.length > 0
          || this.repeatEmail.length > 0
          || this.password.length > 0
          || this.gender.code !== 'NONE'
          || this.birthday !== null;
    }
  },
  methods: {
    ...mapActions(authStore, [
      'openSignUpModal',
      'closeSignUpModal',
      'signUpRequest',
      'toggleLoginModalState',
      'setUnsavedChanges',
      'toggleLeavePageModal'
    ]),
    closeModal(ignoreChangesCheck) {
      if (this.hasChanges && !ignoreChangesCheck) {
        this.toggleLeavePageModal();
        this.setUnsavedChanges(true, this.leavePage);
      } else {
        this.leavePage();
      }
    },
    leavePage() {
      this.submitBtnState = true;
      this.v$.$reset();

      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.repeatEmail = '';
      this.password = '';
      this.gender = { "name": "Gender", "code": "NONE" };
      this.birthday = null;

      this.closeSignUpModal();
    },
    openLoginModal() {
      this.closeModal(true);
      this.toggleLoginModalState();
    },
    async signUp() {
      const isFormCorrect = await this.v$.$validate()
      const formData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        gender: this.gender.code,
        birthday: moment(this.birthday).format('YYYY-MM-DD')
      };

      if (this.submitBtnState) {
        this.v$.$touch();
        if (isFormCorrect && this.userAgeCheck) {
          this.submitBtnState = false;
          this.signUpRequest(formData);
        }
      }
    }
  },
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="sign-up-modal"
          modal
          :header="'Join PeoplePolitics'"
  >
    <div>
      <div class="field-wrap">
        <input type="text"
               v-model="firstName"
               :placeholder="$t('auth.signUpForm.firstname')"
               class="form-control"
               :class="{'form-control--error': v$.firstName.$error}"
               @focus="firstNameActive = true"
               @blur="firstNameActive = false"
               maxlength="30"
               autocomplete="off"
        />
        <span v-for="error of v$.firstName.$errors" :key="error.$uid"
              class="form-control-error"
        >
          <template v-if="!firstNameActive">{{ error.$message }}</template>
        </span>
        <span v-if="firstNameActive" class="form-control__char-count">
          {{30 - firstName.length}}
        </span>
        <div v-if="firstNameActive" class="form-control__desc">
          {{$t('auth.signUpForm.enterFirstName')}}
        </div>
      </div>
      <div class="field-wrap">
        <input type="text"
               v-model="lastName"
               :placeholder="$t('auth.signUpForm.lastName')"
               class="form-control"
               :class="{'form-control--error': v$.lastName.$error}"
               @focus="lastNameActive = true"
               @blur="lastNameActive = false"
               maxlength="60"
               autocomplete="off"
        />
        <span v-for="error of v$.lastName.$errors" :key="error.$uid"
              class="form-control-error"
        >
          <template v-if="!lastNameActive">
            {{ error.$message }}
          </template>
        </span>
        <span v-if="lastNameActive" class="form-control__char-count">
          {{30 - lastName.length}}
        </span>
        <div v-if="lastNameActive" class="form-control__desc">
          {{$t('auth.signUpForm.enterLastName')}}
        </div>
      </div>
      <div class="field-wrap">
        <input type="text"
               v-model="email"
               :placeholder="$t('auth.signUpForm.email')"
               class="form-control"
               :class="{'form-control--error': v$.email.$error}"
               @focus="emailActive = true"
               @blur="emailActive = false"
               maxlength="60"
               autocomplete="off"
        />
        <span v-for="error of v$.email.$errors" :key="error.$uid"
              class="form-control-error"
        >
          <template v-if="!emailActive">
            {{ error.$message }}
          </template>
        </span>
        <span v-if="emailActive" class="form-control__char-count">
          {{60 - email.length}}
        </span>
        <div v-if="emailActive" class="form-control__desc">
          {{$t('auth.signUpForm.enterEmailName')}}
        </div>
      </div>
      <div class="field-wrap">
        <input type="text"
               v-model="repeatEmail"
               :placeholder="$t('auth.signUpForm.reEnterEmail')"
               class="form-control"
               :class="{'form-control--error': v$.repeatEmail.$error}"
               @focus="repeatEmailActive = true"
               @blur="repeatEmailActive = false"
               maxlength="60"
               autocomplete="off"
        />
        <span v-for="(error, index) of v$.repeatEmail.$errors" :key="error.$uid"
              class="form-control-error"
        >
          <template v-if="index === 0 && !repeatEmailActive">
            {{ error.$message }}
          </template>
        </span>
        <span v-if="repeatEmailActive" class="form-control__char-count">
          {{60 - repeatEmail.length}}
        </span>
        <div v-if="repeatEmailActive" class="form-control__desc">
          {{$t('auth.signUpForm.pleaseReEnterEmail')}}
        </div>
      </div>
      <div class="field-wrap">
        <input type="password"
               v-model="password"
               :placeholder="$t('auth.signUpForm.password')"
               class="form-control"
               :class="{'form-control--error': v$.password.$error}"
               @focus="passwordActive = true"
               @blur="passwordActive = false"
               maxlength="60"
               autocomplete="off"
        />
        <span v-for="(error, index) of v$.password.$errors" :key="error.$uid"
              class="form-control-error"
        >
           <template v-if="index === 0 && !passwordActive">
            {{ error.$message }}
          </template>
        </span>
        <span v-if="passwordActive" class="form-control__char-count">
          {{60 - password.length}}
        </span>
        <div v-if="passwordActive" class="form-control__desc">
          {{$t('auth.signUpForm.passwordMustContain')}}
        </div>
      </div>
      <div class="field-wrap">
        <Select v-model="gender" :options="[
              { name: 'Gender', code: 'NONE' },
              { name: 'Male', code: 'MALE' },
              { name: 'Female', code: 'FEMALE' }
            ]"
                optionLabel="name"
                overlayClass="sign-up-gender-select-overlay"
                class="sign-up-gender-select"
        />
      </div>
      <div class="field-wrap">
        <DatePicker v-model="birthday"
                    dateFormat="dd/mm/yy"
                    :class="{'p-datepicker--error': (v$.birthday.$error || !userAgeCheck)}"
                    :placeholder="$t('auth.signUpForm.selectDate')"
        />

        <span v-for="error of v$.birthday.$errors" :key="error.$uid"
              class="form-control-error"
        >
          {{ error.$message }}
        </span>
        <span v-if="!userAgeCheck && birthday"  class="form-control-error">
          {{$t('validations.ageNotValid')}}
        </span>
      </div>
      <div class="form-info">
        <span class="form-label">
          {{$t('auth.signUpForm.byClickingJoin')}}
          <span class="form-label form-label--link">
            {{$t('auth.signUpForm.terms')}}
          </span>.
        </span>
      </div>
      <div class="form-info" style="margin: 0">
        <span>
          {{$t('auth.signUpForm.alreadyMember')}}
          <a class="link" @click="openLoginModal">{{$t('auth.login')}}</a></span>
      </div>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal()">
        {{$t('common.buttons.cancel')}}
      </button>
      <button class="p-dialog-primary-btn" @click="signUp"
              :class="{'disabled': !submitBtnState}"
      >
        {{$t('auth.join')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.sign-up-modal {
  min-width: 500px;
  max-width: 500px;
  .p-dialog-content {
    padding: 20px 30px 25px;

    .form-info {
      display: flex;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 16px;

      .link {
        margin-left: 5px;
        color: #7835a6;
        font-weight: 600;
        cursor: pointer;
      }

      .form-label {
        font-size: 12px;
        &--link {
          color: #7835a6;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    .field-wrap {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 20px;

      .form-control {
        min-width: 205px;
        background: #fff;
        display: inline-block;
        font-size: 12px;
        border: 1px solid #cccccc;
        height: 30px;
        color: #4f4f4f;
        padding: 0 30px 0 8px;
        appearance: none;
        outline: none;
        &::placeholder {
          color: #bbbbbb;
        }
        &:focus {
          border-color: #999999;
          color: #000000;
        }
        &__char-count {
          position: absolute;
          left: 175px;
          top: 5px;
          padding-left: 7px;
          border-left: 1px solid #ccc;
          line-height: 20px;
          font-size: 12px;
          color: #4f4f4f;
        }
        &__desc {
          position: absolute;
          left: 220px;
          font-size: 12px;
          color: #4f4f4f;
        }
        &--error {
          border-color: #fe8f18;
        }
      }

      .form-control-error {
        display: block;
        margin-left: 15px;
        font-size: 12px;
        color: #fe8f18;
      }

      .p-select {
        height: 30px;
        width: 208px;
        border: 1px solid #cccccc;
        font-size: 12px;
        color: #4f4f4f;

        &.p-focus {
          outline: none;
          border-color: #999999;
        }
        .p-select-label {
          font-size: 12px;
          color: #4f4f4f;
        }
      }

      .p-datepicker {
        height: 30px;
        width: 208px;
        min-width: 208px;
        border: none;
        font-size: 12px;
        color: #4f4f4f;

        &--error {
          .p-datepicker-input {
            border-color: #fe8f18 !important;
          }
        }

        &.p-focus {
          outline: none;
          .p-inputtext {
            border-color: #999999;
          }
        }
        .p-inputtext {
          font-size: 12px;
          outline: none;
          border-color: #cccccc;
          &::placeholder {
            color: #bbbbbb;
          }
        }
      }
    }
  }
}

.p-datepicker-panel {
  .p-datepicker-day {
    font-size: 12px;
  }

  .p-datepicker-day-selected,
  .p-datepicker-month-selected,
  .p-datepicker-year-selected {
    background: #a163cc;
  }
}

.sign-up-gender-select-overlay {
  .p-select-list {
    border: 1px solid #ccc;
    .p-select-option {
      .p-select-option-label {
        font-size: 12px;
        color: #3c3c3c;
      }
      &-selected {
        background: #e2e2e2 !important;
      }
    }
  }
}

</style>