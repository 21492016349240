<script>
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "UserMyDesktopView",
  components: {
    BaseIframe
  },
  computed: {
    frameUrl() {
      const url = this.$route.fullPath;
      let newUrl = this.$route.fullPath;

      if (!url.includes('_f=true')) {
        if (!url.match(/\?./)) {
          newUrl += '?_f=true';
        } else {
          newUrl += '&_f=true';
        }
      }

      return newUrl;
    },
  },
}
</script>

<template>
  <Base-iframe
      :disable-loader="false"
      :src="frameUrl"
  />
</template>

<style scoped>

</style>