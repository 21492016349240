<script>
import i18n from "@/i18n";
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";
import moment from "moment-timezone";
export default {
  name: "BaseProposalListItem",
  components: {
    BaseAvatar
  },
  props: {
    itemData: {
      type: Object
    },
    highlightTitle: {
      type: String
    }
  },
  computed: {
    proposalTitle() {
      let title = this.itemData.title;

      if (this.highlightTitle) {
        title = title.replace(this.highlightTitle, '<span class="highlight">' + this.highlightTitle + '</span>')
      }

      return title;
    },
    proposalStatus() {
      const status = this.itemData.status;
      let statusText = '';

      if (status === 'ARCHIVED') {
        statusText = i18n.global.t('proposal.generalInfo.proposalStatus.archived');
      } else{
        statusText = 'To be raised to Riksdagen';
      }

      // TODO fix status after API changes

      return statusText;
    },
    proposalCreateDate() {
      var date = this.itemData.dateTime.substring(0, this.itemData.dateTime.indexOf("["));
      var timeZone = this.itemData.dateTime.replace( /(^.*\[|\].*$)/g, '' );

      return moment(date).tz(timeZone).format('YYYY-MM-DD h:mm');
    },
  },
  methods: {
    openForumView() {
      const forumUrl =  '/forum/' + this.itemData.forum.id;
      window.open(forumUrl, '_blank')
    },
  }
}
</script>

<template>
  <div class="proposals-item">
    <div class="forum-flag" @click="openForumView">
      <div class="forum-flag__icon riksdagen-icon"></div>
      {{itemData.forum ? itemData.forum.name : ''}}
    </div>
    <router-link :to="{name: 'ProposalView', params: {id: itemData.id}}"
                 class="proposal-header"
                 v-html="proposalTitle"
    ></router-link>
    <div>
      <div class="proposal-status">{{proposalStatus}}</div>
      <span class="create-date-info">{{ $t('editProposal.lastUpdate') }} {{ proposalCreateDate }}</span>
    </div>
    <div class="proposal-info-row">
      <span class="proposal-info-row__title">
        {{$t('editProposal.proposer')}}:
      </span>
      <div class="proposal-proposer">
        <base-avatar :size="'24'"
                     :img-hash="itemData.proposer.imgHash"
                     class="proposal-proposer__avatar"
        />
        <router-link :to="{name: 'UserView', params: {id: itemData.proposer.id}}"
                     target="_blank"
                     class="proposal-proposer__name"
        >{{ itemData.proposer.name }}</router-link>
      </div>
    </div>
    <div class="proposal-info-row">
      <span class="proposal-info-row__title">
        {{ itemData.proposer.subjects }}
      </span>
      <span class="proposal-info-no-data">
         {{ itemData.proposer.none }}
      </span>
    </div>
  </div>
</template>

<style lang="scss">
.proposals-item {
  width: 100%;
  height: 200px;
  position: relative;
  padding: 15px 22px 8px 17px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f6f7f8;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(79, 79, 79, 0.15);

  .forum-flag {
    display: flex;
    align-items: center;
    position: absolute;
    height: 24px;
    left: 0;
    width: fit-content;
    padding: 5px 24px 5px 6px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #ffffff;
    background: #7835a6;
    cursor: pointer;

    &:after {
      position: absolute;
      top: 0;
      right: -1px;
      content: '';
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #fff;
    }

    &__icon {
      width: 16px;
      height: 20px;
      margin-right: 9px;

      &.riksdagen-icon {
        width: 22px;
        height: 15px;
        background: url("~@/assets/img/proposal/riksdagen-icon.svg"), no-repeat, center;
      }
    }
  }

  .proposal-header {
    color: #7835a6;
    font-family: 'Open Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    display: block;
    padding: 40px 0 12px;
    text-decoration: none;

    .highlight {
      background: #f8f53d;
    }
  }

  .proposal-status {
    display: inline-block;
    background-color: #bbbbbb;
    color: #fff;
    text-transform: none;
    border-radius: 14px;
    padding: 3px 18px;
    font-size: 14px;
    margin-bottom: 16px;
    margin-right: 14px;
  }

  .create-date-info {
    font-size: 12px;
    color: #4f4f4f;
  }

  .proposal-info-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &__title {
      margin-right: 8px;
      font-size: 14px;
      font-weight: 600;
      color: #4f4f4f;
    }
  }

  .proposal-proposer {
    display: flex;
    align-items: center;
    &__avatar {
      margin-right: 6px;
    }
    &__name {
      font-size: 12px;
      color: #4f4f4f;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .proposal-info-no-data {
    font-size: 14px;
    color: #808080;
  }
}
</style>